.custom-select-arrow {
	&.active {
		.form-control {
			border-color: #42b12d;
			-webkit-appearance: none;
			-moz-appearance: none;
		}

		&:before {
			content: "\f00c";
			font-family: "Font Awesome 5 Free";
			position: absolute;
			right: 28px;
			top: 8px;
			font-weight: 900;
			color: #42b12d;
		}
	}
}

.white-box {
	&.sidebar-box {
		border-top: 3px solid #4b9ee2;


		ul.list-style {
			&.blue-list-style {
				li {
					&:before {
						color: var(--lightBlueColor);
						top: 0;
					}
				}
			}
		}
	}
}

.white-box1 {
	&.sidebar-box1 {
		border-top: 3px solid #4b9ee2;


		ul.list-style {
			&.blue-list-style {
				li {
					&:before {
						color: var(--lightBlueColor);
						top: 0;
					}
				}
			}
		}
	}
}

.team-name {
	padding: 5px;
	background-color: var(--greyColor);
	font-size: 12px;
}

.way-map {
	iframe {
		max-width: 100%;
		height: 500px;
		width: 100%;
	}
}

.offer-box {
	padding: 10px;
	position: relative;
	margin: 0;
	background-color: #ECF7FD;
	color: var(--defaultColor);

	@media (max-width:767px) {
		display: block
	}

	.offer-left {
		background: var(--lightBlueColor);
		color: var(--whiteColor);
		padding: 5px 7px;
		border: 1px dashed var(--darkBlueColor);
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 0;
		font-size: 12px;
	}

	ul {
		padding: 0;
		list-style: none;
		margin: 0;

		li {
			line-height: 14px;
			font-size: 12px;
			margin: 0 0 5px;
		}
	}
}

.region-view,
.payment-view {
	padding-top: 80px;
	padding-bottom: 80px;
}