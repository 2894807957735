@import "setup.css";

.vo-nav-bar {
	.top-bar {
		background: var(--lightBlueColor);
		position: relative;
		z-index: 2;
		min-height: 60px;
		padding: 11px 0;

		@media (max-width: 991px) {
			.search {
				width: 200px;
			}
		}

		@media (max-width: 767px) {
			display: none;
		}

		.top-link {
			color: var(--whiteColor);
			display: block;
			background: #3b8cda;
			border: 1px solid #3b8cda;
			margin: 0 0 0 15px;
			min-width: 157px;
			text-align: center;
			padding: 0 15px;
			line-height: 33px;

			@media (max-width: 991px) {
				padding: 0 7px;
			}

			&.favourite {
				min-width: 140px;
				display: flex;
				align-items: center;

				.far {
					margin-right: 8px;
					font-size: 16px;
				}
			}

			&:hover {
				background-color: var(--lightBlueColor);
			}

			.fas {
				margin-right: 6px;
			}
		}
	}

	.header-menu {

		.navbar-brand {
			position: absolute;
			display: block;
			z-index: 9;
			top: -75px;
			padding: 22px;
			margin: 0;
			background: var(--whiteColor);

			@media (max-width: 991px) {
				padding: 12px;
			}

			@media (max-width: 767px) {
				position: static;
				width: 40px;
				padding: 5px 0;
			}
		}

		.navbar {
			padding: 0;

			@media (max-width: 767px) {

				.favourite-mobile {
					display: block;
					line-height: 32px;

					span {
						width: 35px;
						height: 35px;
						text-align: center;
						background-color: var(--greyColor);
						border-radius: 100%;
						display: inline-block;
						vertical-align: top;
						line-height: 36px;
						font-size: 20px;
						margin-right: 3px;
					}

					&:hover {
						background: none;
						color: var(--darkBlueColor);
					}
				}

				.mobilePhone {
					padding: 15px 15px 5px;
					display: block;
					color: #000;
					font-weight: 600;
				}

				.navbar-toggler:focus {
					outline: 0 none;
				}

				.navbar-toggler-icon {
					width: 24px;
					height: 18px;
					background-image: none;
					position: relative;
					border-bottom: 2px solid #000;
					transition: all 300ms linear;

					&:before,
					&:after {
						width: 24px;
						position: absolute;
						height: 2px;
						background-color: #000;
						top: 0;
						left: 0;
						content: '';
						z-index: 2;
						transition: all 300ms linear;
					}

					&:after {
						top: 8px;
					}
				}

				.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
					transform: rotate(45deg);
				}

				.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
					transform: translateY(8px) rotate(-45deg);
				}

				.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
					border-color: transparent;
				}
			}

			.navbar-collapse {
				@media (max-width: 767px) {
					margin: 0 -15px;
					border-top: 2px solid #E2E3E5;
				}

				.navbar-nav {

					@media (max-width: 767px) {
						padding: 0 15px;

						.nav-item {
							transition: all 200ms linear;

							.nav-link {
								font-size: 15px;
								color: var(--defaultColor);
								border-bottom: 1px solid #EDEDED;
								padding: 13px 0;
								font-weight: 600;

								&:hover {
									background: none;
									color: var(--defaultColor);
								}

								&.dropdown-toggle {
									&:after {
										content: "\f107";
										font-family: "Font Awesome 5 Free";
										transition: all 200ms linear;
										font-weight: 900;
										font-size: 18px;
										width: 48px;
										height: 48px;
										right: 0;
										top: 0;
										position: absolute;
										display: block;
										text-align: center;
										background-color: #fff;
										border: none;
										border-left: 1px solid #EDEDED;
										line-height: 48px;
										z-index: 9;
									}
								}
							}

							&.show {
								.dropdown-toggle {
									&:after {
										content: "\f106";
									}
								}
							}

							.dropdown-menu {
								margin: 0;
								padding: 0;
								border: none;
								box-shadow: none;
								transform: translate3d(0, 10px, 0);
								visibility: hidden;
								opacity: 0;
								max-height: 0;
								display: block;
								transition: all 200ms linear;

								.dropdown-item {
									margin: 0 47px 0 15px;
									padding: 13px 0;
									color: var(--defaultColor);
									font-size: 14px;
									background: none;
									border-bottom: 1px solid #ededed;
									width: auto;

									.fas {
										display: none;
									}

									&:hover {
										color: var(--lightBlueColor);
									}
								}
							}

							&.show {
								.dropdown-menu {
									opacity: 1;
									visibility: visible;
									max-height: 999px;
									transform: translate3d(0, 0px, 0);
								}
							}
						}
					}

					@media (min-width: 768px) {
						.nav-item {
							.nav-link {
								font-size: 20px;
								font-family: 'Oswald', sans-serif;
								font-weight: 600;
								color: var(--defaultColor);
								text-transform: uppercase;
								padding: 25px 0;
								margin-left: 50px;

								&.active {
									color: var(--lightBlueColor);
								}

								&:hover {
									background: none;
									color: var(--lightBlueColor);
								}

								&.dropdown-toggle {
									&:after {
										display: none;
									}
								}
							}

							.dropdown-menu {
								transform: translate3d(0, 20px, 0);
								visibility: hidden;
								opacity: 0;
								max-height: 0;
								display: block;
								padding: 0;
								margin: 0;
								transition: all 200ms linear;
								box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .2);
								background-color: var(--whiteColor);
								right: 0;
								left: auto;
								border: none;
								border-radius: 0;
								width: 220px;

								.dropdown-item {
									padding: 8px 15px;
									color: var(--defaultColor);
									font-size: 14px;
									background: none;

									.fas {
										margin-right: 3px;
									}

									&:hover {
										color: var(--lightBlueColor);
										background: #F5F4F3;
									}
								}
							}

							&.show {
								.dropdown-menu {
									opacity: 1;
									visibility: visible;
									max-height: 999px;
									transform: translate3d(0, 0px, 0);
								}
							}
						}
					}

					@media (min-width: 992px) {
						.nav-item {
							.nav-link {
								margin-left: 30px;
							}
						}
					}

					@media all and (max-width: 991px) and (min-width: 768px) {
						.nav-item {
							.nav-link {
								font-size: 15px;
								margin-left: 15px;
							}
						}
					}
				}
			}
		}




	}

	.search {
		background-color: #3b8cda;
		border: 1px solid #3b8cda;
		transition: all ease 0.3s;

		&:hover {
			background-color: var(--lightBlueColor);
			cursor: pointer;
		}

		.form-control {
			background: none;
			border: none;
			border-radius: 0;
			color: var(--whiteColor);

			@media (min-width: 992px) {
				min-width: 225px;
			}

			&::-webkit-input-placeholder {
				color: #fff;
				opacity: 1;
			}

			&::-moz-placeholder {
				color: #fff;
				opacity: 1;
			}

			&:-ms-input-placeholder {
				color: #fff;
				opacity: 1;
			}

			&:-moz-placeholder {
				color: #fff;
				opacity: 1;
			}
		}

		.btn {
			border-radius: 0;
			background: none;
			color: var(--whiteColor);
			border: none;
			padding: 10px 7px 10px 0;
		}
	}

	.vo-dropdown {
		float: left;
		overflow: hidden;
	}


	.vo-dropdown .dropbtn {
		font-size: 17px;
		border: none;
		outline: none;
		color: white;
		padding: 14px 16px;
		background-color: inherit;
		font-family: inherit;
		margin: 0;
	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	}

	a:hover,
	.vo-dropdown:hover .dropbtn {
		background-color: #555;
		color: white;
	}

	.vo-dropdown-content a:hover {
		background-color: #ddd;
		color: black;
	}

	.vo-dropdown:hover {
		color: red;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}
}

#m-ferienunterkuenfte {
	display: none;
}

@media screen and (max-width: 1200px) and (min-width: 768px) {

	#m-ferienunterkuenfte {
		display: block;
	}

	#m-ferienwohnung {
		display: none;
	}

	#m-ferienhouse {
		display: none;
	}
}