.booking-view {

	.doc-box {
		vertical-align: top;
	}

	textarea.form-control {
		height: 90px !important;
	}

	.cart-details {

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}
	}

	.pd-form {
		label {
			font-weight: normal;
		}

		label.lrequired {
			font-weight: 400;
		}



	}

	.price-table {
		.service-title {
			button {
				display: none;
			}
		}
	}

	.final-col {
		.checks {
			margin-bottom: 10px;

			label {
				font-weight: 700;
			}
		}

		margin-top: 20px;
	}

	.flow-btn {
		margin-left: 0;
		margin-bottom: 0;
	}


	.pay-online-box {
		min-height: 240px;

		.amount-options-list {
			padding-left: 10px;
		}

		.amount-option-btn {
			list-style-type: none;
		}

		.amount-option {
			border: none;
			font-weight: bold;
			padding: 0;
			margin-bottom: 4px;
			margin-left: 10px;
		}

		.amount-or {
			margin-top: 2px;
			font-weight: normal;
			font-style: italic;
		}

		.bank-detail-box {
			padding-left: 10px;
		}
	}


}