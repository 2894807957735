@import "common.css";

.unit-offers-v1 {
	.unit-offer {
		padding: 30px;
		background-color: #52b1ff;
		color: #fff;
		position: relative;
		margin-bottom: 30px;

		&:first-child {
			margin-top: 30px;
		}

		h4 {
			color: #fff;
		}

		.offer-banner {
			position: absolute;
			background-color: #c00;
			color: var(--whiteColor);
			top: -13px;
			left: -8px;
			padding: 2px 10px;
			text-transform: uppercase;
			font-size: .75rem;
			border-radius: 5px;
			box-shadow: 1px 0 3px 0 rgb(0 0 0 / 35%);
		}
	}
}

.backlink {
	a {
		text-decoration: underline;
		font-size: 13px;
		margin: 14px 0;
		display: inline-block;
		vertical-align: top;

		@media (max-width: 767px) {
			margin: 10px 0 15px;
		}

		&:hover {
			text-decoration: none;
		}

		.fas {
			font-size: 17px;
			margin: 2px 6px 0 0;
			float: left;
		}
	}
}

.menu-shortBar {
	display: none;

	.bg-light {
		box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .15);
		background: var(--whiteColor) !important;

		.nav-link {
			font-weight: 700;
			color: var(--defaultColor);

			&.active,
			&:hover {
				background-color: #E9F0FA;
				color: var(--defaultColor);
			}
		}

	}

	.fixed-top {
		z-index: 800;
	}
}

/*.vo-unit-html {
	.grey-bg {
		padding-top: 0 !important;
	}

	.vo-nav-bar .header-menu .navbar-brand {

		@media (min-width:768px) {
			padding: 14px 15px 0;
		}

		@media (min-width:992px) {
			padding: 22px 22px 0;
		}
	}
}*/

.unit-view {

	.white-box1 {
		&.affix {
			@media (min-width:993px) {
				position: fixed;
				max-width: 277px;
				width: 100%;
				z-index: 1050;
				top: 55px;
			}
		}
	}

	.fa-map-marker-alt {
		color: #0cb11a;
		margin-right: 3px;
	}

	.legend {
		margin-top: 15px;
	}

	.legend-cal {
		text-align: center;
		padding: 0;
		margin: 0;

		@media (min-width: 768px) {
			display: flex;
		}

		>span {
			margin-top: 8px;
			margin-right: 10px;
		}

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0;
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 20px;
			position: relative;
			margin: 5px 0;
			padding-left: 30px;
			text-align: left;

			@media (min-width: 993px) {
				padding-left: 40px;
			}

			.day {
				width: 30px;
				height: 30px;
				background-color: #7e7975;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;
				position: absolute;
				left: 0;
				top: 0;
				margin: 0;

				@media (max-width:992px) {
					width: 25px;
					height: 25px;
					top: 5px;
				}

				&.day-free {
					background-color: #a6e1ac;
					color: #306b2e;
					text-decoration: underline;
					font-size: 16px;
					font-weight: bold;
					text-align: center;
					font-style: normal;

					@media(max-width: 992px) {
						font-size: 14px;
					}
				}

				&.day-gap {
					background-color: #a6e1ac;
					font-size: 16px;
					text-align: center;
					color: #b40000;
					font-style: normal;

					@media(max-width: 992px) {
						font-size: 14px;
					}
				}

				&.day-full {
					background-color: var(--not-available);
				}

				&.day-start {
					background: #4b9ee2;
					background: linear-gradient(135deg, #B40000 50%, #a6e1ac 0) !important;
				}

				&.day-end {
					background: #4b9ee2;
					background: linear-gradient(135deg, #a6e1ac 50%, #B40000 0) !important;
				}

				&.day-disable {
					background-color: #4DC359 !important;
				}
			}
		}
	}

	.fav-section {
		.btn {
			font-size: 20px;
			padding: 8px 10px 7px;

			@media (max-width: 1199px) {
				white-space: normal;
				word-break: break-all;
			}
		}
	}

	.ratings {
		.stars {
			background: #f5f4f3;
			padding: 3px 10px;
			text-align: right;
			float: right;

			@media (max-width: 767px) {
				float: left;
				margin: 10px 0;
			}

			.fa {
				color: #50B948;
				margin-left: 3px;
				font-size: 17px;
			}
		}
	}

	.ratings1 {
		h2 {
			.fa-star {
				color: #F7B612;
			}
		}

		.properties {
			.fa {
				color: #50B948;
			}
		}

		.feedbacks {
			margin-top: 10px;

			.fa.fa-star,
			.fa.fa-star-o {
				color: var(--lightBlueColor);
			}

			footer {
				font-weight: 700;
				font-style: italic;
				margin-top: 3px;
			}

			.customer-comment {
				background: #f5f5f5;
				padding: 15px;
				margin: 15px 0 0 0;
				position: relative;

				&:before {
					position: absolute;
					display: block;
					width: 0;
					height: 0;
					top: -10px;
					margin-left: 5px;
					content: " ";
					border-color: #f5f5f5 transparent transparent;
					border-style: solid;
					border-width: 10px 10px 0;
					-webkit-transform: rotate(178deg);
					transform: rotate(178deg);
				}
			}
		}
	}

	.facility-box {
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: grid;
			gap: 20px;
			grid-template-columns: repeat(4, 1fr);
			/* 4 gleichmäßige Spalten */

			@media (max-width: 768px) {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		li {
			display: flex;
			align-items: center;

			i {
				margin-right: 10px;
				font-size: 1.2rem;
			}

			.fas,
			.far {
				font-size: 24px;
				margin-bottom: 5px;
				color: var(--lightBlueColor);
			}
		}
	}

	.unitPropertiesBlock {

		>div {
			border-bottom: 1px solid #ddd;
			margin-bottom: 12px;
			padding-bottom: 12px;

			p {
				margin-bottom: 0;
			}

			ul {
				li {
					float: left;
					width: 25%;
					padding-left: 15px;

					@media (max-width: 767px) {
						width: 100%;
					}

					&:before {
						background: #0cb11a;
					}
				}
			}
		}

		&:last-child {

			>div {
				border-bottom: none;
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
	}



	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin: 0 -5px;

		.visible-print {
			display: none !important;
		}

		&.unitGallery {
			a {
				display: none;
				padding: 5px;

				img {
					width: 100%;
				}

				&:nth-child(1) {
					width: 70%;
					float: right;
					display: block;
					max-height: 347px;
					overflow: hidden;

					@media (max-width: 767px) {
						width: 50%;
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					width: 30%;
					float: left;
					display: block;

					@media (max-width: 767px) {
						width: 50%;
					}
				}

				&:nth-child(4) {
					clear: both;

					@media (max-width: 767px) {
						clear: none;
					}
				}

				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7),
				&:nth-child(8) {
					display: block;
					width: 20%;
					float: left;

					@media (max-width: 767px) {
						width: 50%;
					}
				}

				&:nth-child(8) {
					position: relative;

					&:before {
						content: "alle 18 Bilder anzeigen";
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						bottom: 0;
						text-align: center;
						width: 100%;
						color: #fff;
						font-weight: 600;
						font-size: 13px;
						word-break: break-all;
						margin-top: -10px;
						z-index: 1;
					}

					&:after {
						content: "";
						position: absolute;
						left: 5px;
						right: 5px;
						top: 5px;
						bottom: 5px;
						background: rgba(0, 0, 0, .5);
						z-index: 0;
					}

				}

				@media (max-width: 767px) {
					&:nth-child(odd) {
						clear: both;
					}
				}
			}
		}
	}

	.search-section {
		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;

		max-width: 1200px;

		h2 {
			margin-top: 40px;
			font-size: 22px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 15px 15px 5px 15px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				margin-left: 15px;
				margin-right: 15px;
				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		@media (min-width:768px) {
			.info-col.noaction {
				position: absolute;
				right: 0;
				top: -41px;

				width: 33.33333333%;
				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

				.info-col-header {
					height: 40px;
					padding: 1px;
					background-color: rgba(60, 63, 64, 0.9);
					color: #fff;
					text-align: center;

					h2 {
						margin-top: 10px;
						font-size: 18px;
					}
				}

				.info-col-main {
					border: 1px solid #888;
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}

		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 400px;
			width: 100%;
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header h1 {
			font-size: 24px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}

.stickysidebar {
	position: fixed;
	/* Fixed Sidebar (stay in place on scroll) */
	z-index: 1;
	/* Stay on top */


}

.unit-room-details-v1 {

	display: flex;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.rooms {

		width: 50%;
		max-width: 49%;
		border: 1px solid #9d9b99;
		padding: 10px;
		margin-bottom: 15px;

		@media (max-width:767px) {
			width: 100%;
			max-width: 100%;
		}

		.room {
			font-weight: 600;

			@media (max-width:992px) {
				font-size: var(--font-size-md);
			}

		}

		.room-details {
			ul {

				li {
					padding-left: 0px;
					position: relative;

					@media (max-width:992px) {
						font-size: var(--font-size-md);
					}
				}
			}
		}

	}
}