:root {
	--innerPageBanner: url(RESOURCE/img/inner-banner.jpg);
	--innerSearchPageBanner: url(RESOURCE/img/searchBanner1.jpg);
	--navbarbreak: 768px;
	--defaultColor: #000;
	--headingColor: #292929;
	--lightBlueColor: #4b9ee2;
	--darkBlueColor: #0162a9;
	--darkBlueColor1: #0062A9;
	--whiteColor: #fff;
	--greyColor: #F5F4F3;
	--hover-dt: #0162a9;
	--not-available: #B40000;
	--dt-green: #3c9d46;
}