@import "common.css";
@import "setup.css";

/*.vo-search-html {
	min-height: 100%;
	height: 100%;
}*/

.vo-search-body {

	@media (max-width: 991px) {
		#filter-mobile {
			display: none;
			padding: 0;
		}
	}

	@media (max-width: 767px) {
		.modal-dialog {
			margin-left: 0;
			margin-right: 0;
		}
	}



	#filter-mobile.modal {

		@media (min-width: 992px) {
			opacity: 1;
			position: static;
			display: flex;
			overflow: visible;

			.modal-dialog {
				transform: none;
				margin: 0;
				max-width: 100%;
				display: flex;
			}
		}

		.modal-content {
			border: 0;
			border-radius: 0;
			display: flex;
			background: none;

			#searchpanel {
				height: 100%;
			}

			.close {
				color: var(--whiteColor);
				opacity: 1;
				text-shadow: none;
				font-size: 26px;
				margin-top: -3px;
			}
		}
	}


	.result-pane {

		.result-header {

			.sorting-trigger {
				cursor: pointer;
				border-left: 1px solid #ccc;
				padding-left: 10px;
				padding: 4px 0 4px 15px;
				display: block;
				vertical-align: top;
				position: relative;

				&:before {
					content: '\f107';
					font-family: 'Font Awesome 5 Free';
					color: #000;
					font-weight: 900;
					font-size: 16px;
					float: right;
					margin-right: 0;
					line-height: 22px;
				}
			}

			.box {
				width: 210px;
				transform: none !important;
				top: 45px !important;
				border: none !important;
				border-radius: 0 !important;
				box-shadow: none !important;
				right: 15px !important;
				left: auto !important;


				.body {
					padding: 0;

					.list-group {
						margin: 0;

						.list-group-item {
							border-color: #e6e6e6;
							border-radius: 0;
							padding: 8px;
							font-size: 13px;
							color: #000;

							span {
								color: #000;
								font-size: 13px;
							}
						}
					}
				}

				.arrow {
					display: none;
				}
			}

			.mysearch {
				margin-bottom: 4px;

				.my-search-token {
					background: #4b9ee2;
					border: none;
					color: #fff;
					font-size: 13px;
					line-height: 23px;
					margin-top: 7px;
					padding: 4px 15px;

					.close {
						color: #fff;
						text-shadow: none;
						opacity: 1;
						font-size: 20px;
						padding: 0;
						margin: 2px 0 2px 5px;
					}
				}
			}
		}

		.right-accommodation-block {
			margin: 30px 0 30px;
			text-align: center;
		}

		.pagination {
			margin: 30px 0 0;
			justify-content: center;

			@media (max-width: 992px) {
				margin: 5px 0 20px;
			}

			li {
				display: inline-block;
				vertical-align: middle;

				a {
					background-color: var(--darkBlueColor);
					border-radius: 3px;
					color: var(--whiteColor);
					box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
					width: 40px;
					height: 40px;
					display: block;
					margin-right: 10px;
					text-align: center;
					line-height: 40px;

					&:hover {
						background-color: var(--lightBlueColor);
						color: var(--whiteColor);
					}
				}

				&.active {
					a {
						background-color: var(--lightBlueColor);
						color: var(--whiteColor);
					}
				}
			}
		}

	}

	.home-rating {
		.rating-star {
			position: relative;

			.fas {
				color: #46C400;
				font-size: 60px;
			}

			span {
				position: absolute;
				left: 0;
				right: 0;
				text-align: center;
				font-size: 20px;
				color: var(--whiteColor);
				line-height: 64px;
				font-weight: 600;
			}
		}

		.rating-text {
			h5 {
				font-size: 14px;
				font-weight: 600;
				font-family: Open Sans, sans-serif;
			}

			p {
				font-size: 13px;
			}
		}
	}

	.search-title-header {
		background-color: var(--whiteColor);

		.map {
			position: relative;
			line-height: 20px;
			color: var(--defaultColor);
			display: block;
			padding: 5px 0;
			border-right: 1px solid #ccc;

			&:before {
				content: '\f57e';
				font-family: 'Font Awesome 5 Free';
				color: #666;
				font-weight: 900;
				font-size: 18px;
				float: left;
				margin-right: 10px;
			}
		}

		.result-count {
			font-size: 15px;
			font-weight: 700;
		}
	}

	.map-view {

		.result,
		.results,
		.pagination-panel,
		.right-accommodation-block {
			display: none;
		}

		.map-pane {
			-webkit-box-flex: 1;
			-webkit-flex: 1 1 0;
			-ms-flex: 1 1;
			flex: 1 1 0;
			min-width: 0;
			min-height: 0;
			flex-direction: inherit;

			.header {
				display: none;
			}

			.flex {
				-webkit-box-flex: 1;
				-webkit-flex: 1 1 0;
				-ms-flex: 1 1;
				flex: 1 1 0;
				min-width: 0;
				min-height: 0;
				height: 600px;

				@media (max-width: 991px) {
					height: 450px;
				}

				@media (max-width: 767px) {
					height: 250px;
				}
			}

			.leaflet-popup-content {
				width: 270px !important;
				margin: 0;
				padding: 15px;
			}
		}
	}

	.map-pane {

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.fav-icon {
			border: 1px solid var(--lightBlueColor);
			color: var(--lightBlueColor);
			border-radius: 3px;
			display: block;
			width: 24px;
			height: 24px;
			text-align: center;
			line-height: 24px;
			background: #f5f4f3;
			min-width: 24px;
			min-height: 24px;
		}

		.dtl {
			border-top: 1px solid #f3f4f6;
			border-bottom: 1px solid #f3f4f6;
			padding: 7px 0 12px;
			margin: 8px 0;

			li {
				padding-left: 15px;

				&:before {
					background: #0cb11a;
				}
			}
		}

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			width: 100%;
			position: relative;
			margin-top: 40px;

			@media (max-width: 767px) {
				margin-top: 25px;
			}

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {
					>div {
						width: 100%;
						height: 100%;
					}

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: #fff;
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 15px;
				background-color: var(--whiteColor);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.fa-map-marker-alt {
					color: #0cb11a;
					margin-right: 3px;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
				}


			}
		}


	}


	.search-result-properties {
		ul {
			li {
				float: left;
				display: block;
				margin: 5px 5px 0 0;
				padding: 3px;
				border: 1px solid #f3f4f6;
				text-align: center;

				.fas,
				.far {
					vertical-align: top;

					&:before {
						content: "";
						width: 22px;
						height: 22px;
						background-size: 22px 22px;
						display: block;
					}

					&.fa-wifi {
						&:before {
							background-image: url(RESOURCE/img/wifi.svg);
						}
					}

					&.fa-person-booth {
						&:before {
							background-image: url(RESOURCE/img/balcony.svg);
						}
					}

					&.fa-chair {
						&:before {
							background-image: url(RESOURCE/img/terrasse.svg);
						}
					}

					&.fa-dog {
						&:before {
							background-image: url(RESOURCE/img/pets.svg);
						}
					}

					&.fa-dog.not-allowed {
						&:before {
							background-image: url(RESOURCE/img/no-pets.svg);
						}
					}

					&.fa-dumpster-fire {
						&:before {
							background-image: url(RESOURCE/img/dumpster-fire.svg);
						}
					}

					&.fa-fire-alt {
						&:before {
							background-image: url(RESOURCE/img/fire.svg);
						}
					}



					/*color: var(--lightBlueColor);
					font-size: 20px;

					&.not-allowed {
						position: relative;

						&:after {
							content: "";
							background: var(--lightBlueColor);
							width: 2px;
							height: 35px;
							position: absolute;
							transform: rotate(45deg);
							left: 11px;
							top: -6px;
						}
					}*/


				}
			}
		}
	}

	.rating {
		margin-top: 12px;

		.fa-star {
			color: #46C400;
			margin: 1px 5px 0 0;
			font-size: 18px;
			display: inline-block;
			vertical-align: top;
		}
	}

	.unit-rating {
		margin-top: -40px;

		@media (max-width: 767px) {
			margin: 0 0 10px;
		}

		.ratings {
			margin-left: 8px;

			.fa-star {
				color: #F9B000;
			}

			span {
				margin-left: 5px;
			}
		}
	}

	.price-block {
		width: 100%;
		border-left: 1px solid #f5f4f3;
		padding-left: 20px;

		@media (max-width: 991px) {
			border-left: none;
			padding-left: 0;
			margin-top: 10px;
		}

		.prices {
			font-size: 12px;

			.price-bl {
				font-size: 11px;

				.price-start {
					font-size: 15px;
					font-weight: 300;
					font-family: Oswald, sans-serif;
				}

				.price-value {
					font-size: 22px;
					font-family: Oswald, sans-serif;
				}

				.per-night {
					display: block;
				}
			}
		}
	}




	.side-sticky {
		@media (min-width:993px) {
			position: sticky;
			top: 0;
			z-index: 5;
		}
	}

	.search-bar {
		background-color: #4b9ee2;
		padding: 15px;



		h4 {
			color: var(--whiteColor);
		}

		.clear-filter {
			a {
				color: var(--whiteColor);
				font-size: 13px;
			}
		}

		.form-control[readonly] {
			background: var(--whiteColor);
			border: none;
			margin: 0;
		}

		.btn-white {
			border-radius: 0;
			color: var(--defaultColor);
			border: none;
			margin: 10px 0 0;
			text-align: left;
			font-weight: 400;
			padding: 10px;
			background: var(--whiteColor);
		}

		.form-control {
			background: var(--whiteColor);
			margin: 10px 0 0;
		}

	}

	.search-filter {
		background: var(--whiteColor);
		padding: 15px;

		.filter-checkbox {
			.form-group {
				margin-bottom: 0;

				.checkbox {
					padding-left: 5px;

					label {
						&:before {
							border-radius: 0;
							border-color: var(--lightBlueColor);
						}

						&:before,
						&:after {
							top: 3px;
						}
					}
				}
			}
		}
	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	@media (min-width:768px) {
		.map-pane {
			@mixin flex;

		}

		.results {
			.srow {
				@mixin flexlayout row;

				.info-col {
					width: 60%;

					h3 {
						a {
							color: var(--defaultColor);
						}
					}
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						position: absolute;
						@mixin size100;

						.owl-carousel,
						.owl-stage-outer,
						.owl-stage,
						.owl-item,
						.item {
							height: 100%;
						}


						.full-img {
							height: 100%;
							object-fit: cover;
						}
					}

				}

			}
		}



	}





}