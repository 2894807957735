@font-face {
	font-family: 'Oswald';
	src: url('RESOURCE/fonts/Oswald-SemiBold.eot');
	src: url('RESOURCE/fonts/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Oswald-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Oswald-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/Oswald-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/Oswald-SemiBold.svg#Oswald-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Oswald';
	src: url('RESOURCE/fonts/Oswald-Regular.eot');
	src: url('RESOURCE/fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Oswald-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Oswald-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Oswald-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Oswald-Regular.svg#Oswald-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Oswald';
	src: url('RESOURCE/fonts/Oswald-Bold.eot');
	src: url('RESOURCE/fonts/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Oswald-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Oswald-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Oswald-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Oswald-Bold.svg#Oswald-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-SemiBold.eot');
	src: url('RESOURCE/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Regular.eot');
	src: url('RESOURCE/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Regular.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Bold.eot');
	src: url('RESOURCE/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Bold.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}




@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}


.fa-expand-wide {
	background-image: url(RESOURCE/img/icon-expand-wide.svg);
	content: "";
	width: 20px;
	background-position: center;
	display: inline-block;
	filter: brightness(0) saturate(100%) invert(67%) sepia(21%) saturate(5863%) hue-rotate(181deg) brightness(93%) contrast(90%);
	height: 20px;

	&:before {
		content: "";
	}
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {

	h1 {
		color: green;
	}

	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	label.hidelabel {
		display: none;
	}

	.form-control {
		background: var(--whiteColor);
	}

	textarea.form-control {
		height: 90px !important;
	}

	.checkbox {
		padding-left: 5px;
		display: flex;

		label {
			margin-right: 8px;
		}
	}
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}


/* date picker */
.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day-button {
		pointer-events: auto;
	}


	.asd__day-button:disabled,
	.asd__day-button[disabled] {
		pointer-events: none;
	}

	.asd__day--past {
		background: #f0f0f0 !important;
		color: #f0f0f0 !important;
	}

	.asd__day--arrival {
		background: #4B9EE2;
		background: linear-gradient(135deg, #a6e1ac 50%, #B40000 0) !important;
		opacity: 1 !important;
	}



	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background-color: var(-hover-dt);
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background-color: var(-hover-dt);
	}



	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		background-color: var(--dt-green);
		cursor: pointer;
		color: var(--white) !important;

		color:var(--white) button {
			text-decoration: underline;
		}
	}

	.asd__day--enabled {
		background-color: #a6e1ac;
		cursor: pointer;
		color: #306b2e !important;
		font-weight: 800;


		button {
			text-decoration: underline;
			font-size: 16px;
		}

		&:hover {
			background-color: var(--hover-dt) !important;
			color: #fff !important;
		}

		&.asd__day--departure {
			color: #306b2e !important;
			font-weight: 800;
		}

		&.asd__day--disabled {
			color: var(--not-available) !important;
			background: #a6e1ac !important;
			opacity: 1 !important;
			font-weight: normal;

			&.asd__day--past {
				background: #d4d4d4 !important;
				color: #989898 !important;
				opacity: 1 !important;

				&.asd__day--departure,
				&.asd__day--arrival {
					background: #d4d4d4 !important;
					color: #989898 !important;
					opacity: 1 !important;

				}
			}

			button {
				text-decoration: none;
				cursor: not-allowed;
				font-size: 15px;
			}

			&.asd__day--arrival {
				background: #4B9EE2;
				background: linear-gradient(135deg, #a6e1ac 50%, #B40000 0) !important;
				opacity: 1 !important;
				color: var(--whiteColor) !important;
			}

			&.asd__day--selected {
				background-image: url(RESOURCE/img/date-start.png) !important;
				background-repeat: no-repeat !important;
				background-size: 13px !important;
				background-position: right center !important;
				background-color: var(--hover-dt) !important;
			}

			&.asd__day--selected.asd__selected-date-two {
				background-image: url(RESOURCE/img/date-end.png) !important;
				background-repeat: no-repeat !important;
				background-size: 13px !important;
				background-position: 0 !important;
			}

			&.asd__day--departure {
				background-image: url(https://r.v-office.com/v423/1608465875000/img/date-end.png) !important;
				background-repeat: no-repeat !important;
				background-size: 13px !important;
				background-position: 0 !important;

			}

		}



		.asd__day--disabled.asd__day--in-range {
			background: #BAD1E9 !important;
			opacity: 1 !important;
		}

		.asd__day--disabled.asd__day--hovered,
		.asd__day--hovered {
			background: #BAD0EA !important;
			opacity: 1 !important;
		}

		&.asd__day--not-available {
			background: var(--not-available) !important;
			opacity: 1 !important;
			cursor: not-allowed;
			color: var(--white) !important;
		}
	}

	.asd__day--enabled.asd__day--disabled.asd__day--departure {
		background: #4b9ee2;
		background: linear-gradient(135deg, #b40000 50%, #a6e1ac 0) !important;
		opacity: 1 !important;
		color: #fff !important;
		font-weight: normal;
	}

	.asd__day--selected.asd__selected-date-one.asd__day--departure {
		background-image: url(RESOURCE/img/date-start.png) !important;
		background-repeat: no-repeat !important;
		background-size: 13px !important;
		background-position: right center !important;
		background-color: var(--hover-dt) !important;
		color: #fff !important;
		font-weight: normal;
	}

	.asd__day--departure {
		background: #4B9EE2;
		background: linear-gradient(135deg, #B40000 50%, #a6e1ac 0) !important;
		opacity: 1 !important;

	}

	.asd__day--selected {
		background-color: var(--hover-dt) !important;
		color: white !important;
		opacity: 1 !important;

	}

	.asd__day--enabled.asd__day--in-range {
		background: #BAD0EA !important;
		opacity: 1 !important;
	}

	.asd__selected-date-one {
		background-image: url(RESOURCE/img/date-start.png) !important;
		background-repeat: no-repeat !important;
		background-size: 13px !important;
		background-position: right center !important;
	}

	.asd__selected-date-two {
		background-image: url(RESOURCE/img/date-end.png) !important;
		background-repeat: no-repeat !important;
		background-size: 13px !important;
		background-position: left center !important;
	}

	.asd__day {
		border: 1px solid #CCCCCC !important;

		&.asd__day--in-range {
			border-color: #fff !important;
		}

		&.asd__selected-date-one,
		&.asd__day--hovered {
			border-color: #fff !important;
		}
	}

	.asd__month-table {
		.arrow {
			bottom: 0;
			left: 50%;
			margin-left: -5px;
			border-width: 5px 5px 0;
			position: absolute;
			width: 0;
			height: 0;
			border-color: transparent;
			border-style: solid;
			border-top-color: #000 !important;
		}

		.asd__week {
			display: flex;
		}
	}
}



/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

/* Start Custom Css*/

body {
	font-family: 'Open Sans', sans-serif;
	color: var(--defaultColor);
	font-size: 14px;
}

@media (min-width:768px) {
	.container-md {
		max-width: 720px;
	}
}

@media (min-width:992px) {
	.container-lg {
		max-width: 960px;
	}
}

@media (min-width:1200px) {

	.container-xl,
	.container {
		max-width: 1230px;
	}
}

.grey-bg {
	background: var(--greyColor);
}

.checkbox input[type=checkbox]:focus+label:before,
input[type="checkbox"i]:focus,
.checkbox input[type=checkbox]:focus+label:before {
	outline: 0 none !important;
	border-color: #ccc;
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background: var(--whiteColor) !important;
}

.checkbox-success input[type=checkbox]:checked+label:after {
	color: #5cb85c !important;
}

:focus,
input[type="checkbox"]:focus {
	outline: 0 none;
}

b,
strong {
	font-weight: 700;
}

.white-text,
.white-text * {
	color: var(--whiteColor);
}

.h1,
.h2,
.h3,
h1,
h2,
h3,
h4,
h5 {
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 10px;
	color: var(--headingColor);
	font-family: 'Oswald', sans-serif;
}

h1,
.h1 {
	font-size: 24px;
}

h2,
.h2 {
	font-size: 24px;
}

h3,
.h3 {
	font-size: 20px;
}

h4,
.h4 {
	font-size: 18px;
}

h5,
.h5 {
	font-size: 16px;
}

p {
	line-height: 1.5;
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	text-decoration: none;
	color: var(--lightBlueColor);
	transition: all ease 0.3s;

	&:hover,
	&:focus,
	&.active a {
		text-decoration: none;
		outline: 0 none;
		color: var(--darkBlueColor);
	}
}

.fontsize-13 {
	font-size: 13px !important;
}

.form-control {
	font-size: 14px;
	height: 37px !important;
	line-height: 37px;
	padding: 0 10px;
	border-color: #ddd;
	border-radius: 0;
	background: none;
	color: var(--defaultColor);
	background-color: #fff;

	&:focus {
		outline: 0 none;
		box-shadow: none;
		border-color: #ddd;
	}
}


.btn {
	background: var(--darkBlueColor);
	color: var(--whiteColor);
	border-radius: 4px;
	border: 1px solid var(--darkBlueColor);
	box-shadow: none;
	transition: all ease 0.3s;
	font-weight: 600;
	padding: 10px 20px;
	font-size: 14px;
	line-height: 1.2;

	@media (max-width:767px) {
		white-space: inherit;
	}

	&.btn-lg {
		line-height: 1.5;
	}

	&.btn-white {
		background: var(--whiteColor);
		color: var(--lightBlueColor);
		border-color: var(--lightBlueColor);

		&:hover {
			color: var(--whiteColor);
			background: var(--lightBlueColor);
			border-color: var(--lightBlueColor);
		}
	}

	&.btn-sm {
		padding: 8px 20px;
	}



	&:hover,
	&.focus,
	&.active,
	&:active,
	&:focus,
	&:active:focus {
		background: var(--whiteColor);
		color: var(--darkBlueColor);
		outline: 0 none;
		box-shadow: none;
		border-color: var(--darkBlueColor);
	}
}

button:focus {
	outline: 0 none;
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

ul.list-style {
	margin-bottom: 25px;

	li {
		position: relative;
		margin: 3px 0;
		padding-left: 12px;

		&:before {
			content: "\f0da";
			font-family: "Font Awesome 5 Free";
			position: absolute;
			left: 0;
			top: 2px;
			font-weight: 900;
		}
	}

	&.sidebar-list-style {
		li {
			margin: 1px 0;
			padding: 6px 10px 6px 35px;
			background-color: #f5f4f3;
			border: 1px solid #f5f4f3;
			color: var(--lightBlueColor);

			&:before {
				content: "\f105";
				left: 15px;
				top: 7px;
				color: var(--defaultColor);
			}

			a {
				color: var(--defaultColor);

				&:hover {
					color: var(--lightBlueColor);
				}
			}
		}
	}

	&.white-list-style {
		li {
			&:before {
				color: var(--whiteColor);
			}

			a {
				color: var(--whiteColor);

				&:hover {
					color: var(--lightBlueColor);
				}
			}
		}
	}
}

ul.list-style01 {
	margin-bottom: 25px;

	li {
		position: relative;
		margin: 0;
		padding-left: 20px;
		color: var(--defaultColor);

		&:before {
			content: "";
			background: var(--defaultColor);
			width: 5px;
			height: 5px;
			border-radius: 100%;
			position: absolute;
			left: 0;
			top: 9px;
		}
	}
}

.owl-theme {
	.owl-dots {
		position: absolute;
		bottom: 20px;
		width: 100%;

		.owl-dot {
			span {
				width: 12px;
				height: 12px;
				background: var(--whiteColor);
			}

			&.active {
				span {
					background: #666;
				}
			}
		}
	}
}

.owl-nav {

	button {
		width: 42px;
		height: 42px;
		text-align: center;
		position: absolute;
		top: 50%;
		background: rgba(255, 255, 255, 0.5) !important;
		border-radius: 4px !important;
		transition: all ease 0.3s;

		&:hover {
			background: rgba(255, 255, 255, 1) !important;
		}

		&:before {
			font-size: 30px;
			color: var(--defaultColor);
			font-family: 'Font Awesome 5 Pro';
			font-weight: 900;
		}

		span {
			display: none;
		}

		&.owl-prev {
			left: 20px;
			margin: -22px 0 0 !important;

			&:before {
				content: "\f104";
			}
		}

		&.owl-next {
			right: 20px;
			margin: -22px 0 0 !important;

			&:before {
				content: "\f105";
			}
		}
	}
}

.breadcrumbs {
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			display: inline-block;
			vertical-align: top;
			padding: 9px 0;
			line-height: 14px;
			font-size: 13px;
			margin-right: 20px;
			color: var(--defaultColor);

			@media (max-width: 767px) {
				padding: 4px 0;
			}

			&:last-child {
				margin-right: 0;
			}

			a {
				display: block;
				position: relative;
				color: #717171;

				&:hover {
					color: var(--lightBlueColor);
				}

				&:before {
					content: '\f105';
					font-family: 'Font Awesome 5 Free';
					font-weight: 900;
					font-size: 14px;
					position: absolute;
					right: -15px;
					top: 1px;
				}
			}
		}
	}
}

.innerBanner {
	min-height: 360px;
	background: var(--innerPageBanner) no-repeat center top;
	-webkit-background-size: cover;
	background-size: cover;
	position: relative;

	&.searchBanner {
		background-image: var(--innerSearchPageBanner);
	}

	&.angeboteBanner {
		background-image: url(RESOURCE/img/angebotebanner.jpg);
	}

	&.kurzurlaubBanner {
		background-image: url(RESOURCE/img/kurzurlaubbanner.jpg);
	}

	&.faqBanner {
		background-image: url(RESOURCE/img/faqbanner1.jpg);
	}

	&.anreiseBanner {
		background-image: url(RESOURCE/img/anreise-banner5.jpg);
	}

	&.serviceburoBanner {
		background-image: url(RESOURCE/img/serviceburobanner1.jpg);
		background-position: center center;
	}

	&.teamBanner {
		background-image: url(RESOURCE/img/teambanner.jpg);
	}

	&.contactBanner {
		background-image: url(RESOURCE/img/contactbanner.jpg);
	}

	&.vermieterBanner {
		background-image: url(RESOURCE/img/vermieterbanner1.jpg);
	}

	&.bookreqBanner {
		background-image: url(RESOURCE/img/bookreqbanner.jpg);
	}

	&.impressumBanner {
		background-image: url(RESOURCE/img/impressumbanner1.jpg);
	}


	@media (max-width: 991px) {
		min-height: 120px;
	}

	.innerContent {
		position: absolute;
		bottom: 30px;
		left: 0;
		right: 0;
		margin: 0 auto;

		h1 {
			text-shadow: 1px 1px 1px #000;
			z-index: 99;
			color: var(--whiteColor);
			font-size: 32px;
			margin: 0;

			@media (max-width: 767px) {
				font-size: 24px;
			}
		}
	}
}

.white-box {
	background: var(--whiteColor);
	padding: 12px;
}

.grey-box {
	background: var(--greyColor);
	padding: 12px;
}

#accordion {
	.card {
		border: none;
		border-top: 1px solid #ccc;
		border-radius: 0;

		.card-header {
			background: none;
			border-radius: 0;
			border: none;
			padding: 0;

			h3 {
				a {
					color: var(--defaultColor);
					cursor: pointer;
					display: block;
					padding: 27px 30px;
					position: relative;

					&:before {
						content: '\f0ab';
						font-family: 'Font Awesome 5 Free';
						color: var(--lightBlueColor);
						font-weight: 900;
						font-size: 20px;
						position: absolute;
						left: 0;
						top: 30px;
					}

					&.collapsed:before {
						content: '\f0a9';
					}

					&:hover {
						color: var(--lightBlueColor);
					}
				}
			}
		}

		.card-body {
			padding: 0 30px 20px;
		}
	}
}

#UnitsSection,
#UnitsSection2 {
	margin-right: -7px;
	margin-left: -7px;

	.unit-list-section {
		padding-right: 7px;
		padding-left: 7px;
	}
}

.unit-box {
	position: relative;
	margin-bottom: 15px;
	background: var(--whiteColor);
	padding: 15px;

	.img-content {
		position: relative;
		overflow: hidden;
		transition: all ease 0.3s;

		&:hover {
			-webkit-filter: brightness(0.8);
			filter: brightness(0.8);
		}


		.fav-icon {
			position: absolute;
			right: 5px;
			top: 5px;
			background: rgba(255, 255, 255, .8);
			padding: 2px 5px;
			border-radius: 100%;
		}

		.map-marker {
			position: absolute;
			bottom: 5px;
			top: inherit;
			right: 5px;

			i {
				color: var(--skyBlueColor);
				font-size: 24px;
			}
		}

		img {
			width: 100%;
		}
	}

	.btn {
		font-size: 16px;
	}

	.rating {
		.fa-star {
			color: #46C400;
			float: left;
			margin: 1px 8px 0 0;
			font-size: 20px;
		}
	}

	.search-result-properties {
		display: flex;
		text-align: center;

		.ft-icon {
			font-size: 12px;
			border-right: 1px solid #f5f4f3;
			padding-right: 10px;
			margin-right: 10px;
			width: 33.33%;

			&:last-child {
				border: none;
				padding: 0;
				margin: 0;
			}

			@media (max-width: 1199px) {
				width: 33.33%;
			}

			div:first-child {
				font-size: 15px;
			}
		}
	}
}

.booking-unit-block {
	.unit-box {
		background: var(--greyColor);

		.ft-icon {
			border-color: var(--whiteColor);
		}
	}
}


@media (min-width: 992px) {
	.h-lg-100 {
		height: 100%;
	}
}

#back_to_top {
	position: fixed;
	bottom: 40px;
	right: 15px;
	z-index: 200;
	background-color: #f5f4f3;
	color: var(--defaultColor);
	font-size: 14px;
	text-align: center;
	border: 1px solid var(--defaultColor);
	width: 50px;
	height: 50px;
	padding: 4px 0;

	.fas {
		font-size: 24px;
		line-height: 20px;
	}

	span {
		display: block;
		font-size: 14px;
		line-height: 16px;
		margin-top: -3px;
	}

	&:hover {
		background-color: var(--lightBlueColor);
		color: var(--whiteColor);
	}

	@media (max-width:991px) {
		display: none;
	}
}

.search-result-properties,
.facility-box {
	li {

		.fas,
		.far {
			vertical-align: top;

			&:before {
				width: 22px;
				height: 22px;
				background-size: 22px 22px;
				display: block;
			}

			&.fa-wifi {
				&:before {
					background-image: url(RESOURCE/img/wifi.svg);
					content: "";
				}
			}

			&.fa-person-booth {
				&:before {
					background-image: url(RESOURCE/img/balcony.svg);
					content: "";
				}
			}

			&.fa-chair {
				&:before {
					background-image: url(RESOURCE/img/terrasse.svg);
					content: "";
				}
			}

			&.fa-dog {
				&:before {
					background-image: url(RESOURCE/img/pets.svg);
					content: "";
				}
			}

			&.fa-dog.not-allowed {
				&:before {
					background-image: url(RESOURCE/img/no-pets.svg);
					content: "";
				}
			}

			&.fa-dumpster-fire {
				&:before {
					background-image: url(RESOURCE/img/dumpster-fire.svg);
					content: "";
				}
			}

			&.fa-fire-alt {
				&:before {
					background-image: url(RESOURCE/img/fire.svg);
					content: "";
				}
			}



			/*color: var(--lightBlueColor);
					font-size: 20px;

					&.not-allowed {
						position: relative;

						&:after {
							content: "";
							background: var(--lightBlueColor);
							width: 2px;
							height: 35px;
							position: absolute;
							transform: rotate(45deg);
							left: 11px;
							top: -6px;
						}
					}*/


		}
	}
}



.hidden {
	display: none;
}

.box {
	&.full-xxs {
		width: 370px;
	}
}

.asd__mobile-only .asd__mobile-close {
	padding: 4px 8px;
	width: 37px;
	height: 34px;
	right: 15px;
	background: none;
	border: 1px solid #e4e7e7;
	color: #82888a;
	font-size: 16px;
	border-radius: 3px;
}

.popover {
	border: 1px solid #ddd;
	box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
	border-radius: 5px;

	.arrow {
		border-top-color: #ddd;
	}

	.popover-title {
		padding: 8px 14px 0;
		margin: 0;
		font-size: 13px;
		background-color: transparent;
		border-bottom: none;
		border-radius: 5px 5px 0 0;
		color: var(--secondary);
		font-family: var(--d-font);
	}

	.popover-content {
		ul {

			li {
				list-style: none;
				font-size: 11px;
				font-family: var(--d-font);
				color: #7e7e7e;
			}
		}
	}
}



.tooltip.in {
	opacity: 1;
}


/*input[type="date"]:before {
	content: attr(placeholder) !important;
	color: #aaa;
	margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
	content: "";
}*/