.indexpage {
	.welcome-right {

		@media (max-width: 767px) {
			padding: 15px 0 15px 0;
			border-left: none;
		}

		@media (min-width: 1200px) {
			padding: 30px 0 15px 40px;
		}

		padding: 15px 0 15px 15px;
		border-left: 1px solid #ccc;
	}

	.cl-cont-bl {
		padding: 20px;

		@media (min-width:993px) {
			padding: 20px 130px;
		}
	}

	.img-box {
		background: var(--whiteColor);

		>a {
			&:hover {
				-webkit-filter: brightness(0.8);
				filter: brightness(0.8);
			}
		}
	}
}