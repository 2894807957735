.main-footer {

	.footer-mobile-links {
		@media (max-width:767px) {
			position: relative;

			&:before {
				position: absolute;
				left: 50%;
				top: 0;
				width: 50%;
				transform: translate(-50%, -10px);
				content: "";
				height: 1px;
				background-color: var(--darkBlueColor);
			}
		}

		.row {
			margin-right: -7px;
			margin-left: -7px;

			.col-3 {
				border-right: 1px solid #e8e8e4;
				padding-right: 7px;
				padding-left: 7px;

				&:last-child {
					border-right: none;
				}

				a {
					display: block;
					text-align: center;

					.fas {
						color: var(--lightBlueColor);
						font-size: 32px;
						line-height: 40px;
						margin-bottom: 5px;
					}

					span {
						display: block;
					}
				}
			}
		}
	}


	.footer-top {
		background: var(--darkBlueColor);
		padding: 35px 0;

		@media (max-width:767px) {
			padding: 12px;
		}

		.footer-heading {
			color: var(--whiteColor);
		}

		.footer-logo {
			margin: 5px 20px 0 0;

			img {
				background: var(--whiteColor);
				padding: 7px;
				max-width: 80px;
			}
		}

		.footer-social {
			a {
				color: var(--whiteColor);
				line-height: 30px;

				.fab {
					font-size: 30px;
					display: inline-block;
					vertical-align: top;
					margin-right: 10px;
				}

				&:hover {
					color: var(--lightBlueColor);
				}

				@media (max-width:767px) {
					span {
						display: none;
					}
				}
			}
		}
	}

	.copyrights {
		background: var(--whiteColor);
		padding: 15px 0;

		@media (max-width:767px) {
			padding: 10px 0;
		}


		.copyrights-links {
			a {
				color: var(--defaultColor);
				display: block;
				margin-right: 20px;
				font-size: 13px;

				&:hover {
					color: var(--lightBlueColor);
				}
			}
		}

		.copyright-text {
			p {
				font-size: 13px;

				@media (max-width:767px) {
					font-size: 11px;
				}
			}
		}
	}
}