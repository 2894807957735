@import "setup.css";

.vo-index-body {
	.covid-dlg {
		margin: 0 auto !important;
		max-width: 520px;

		.box {
			max-width: 500px;
			padding: 20px 45px;

			@media (max-width: 767px) {
				padding: 15px;
			}
		}
	}
}

#owl-quote {
	.owl-dots {
		bottom: -10px;

		.owl-dot {
			span {
				background-color: var(--greyColor);
			}

			&.active {
				span {
					background-color: var(--darkBlueColor);
				}

			}
		}
	}
}

.indexpage {

	.banner-notification {
		position: absolute;
		top: 25px;
		z-index: 9;
		width: 100%;
		left: 270px;
		right: 0;
		max-width: 820px;
		margin: 0 auto;

		@media (max-width: 767px) {
			left: 0;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			left: 0;
		}

		@media all and (min-width: 992px) and (max-width: 1199px) {
			left: 70px;
			top: 45px;
		}

		p {
			font-weight: 800;
			background: var(--lightBlueColor);
			text-align: center;
			font-size: 18px;
			padding: 14px;

			@media (max-width: 767px) {
				font-size: 14px;
				padding: 14px;
			}

			@media (max-width: 374px) {
				font-size: 12px;
				padding: 10px;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				font-size: 16px;
			}
		}
	}

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.iheader {
		min-height: 450px;
		position: relative;

		.banner-slide {
			height: 450px;

			.owl-stage-outer {
				height: 100%;

				.owl-stage {
					height: 100%;

					.owl-item {
						height: 100%;

						.item {
							height: 100%;

							img {
								height: 100%;
								object-fit: cover;
							}
						}
					}
				}
			}
		}

		.slider-search {
			position: absolute;
			bottom: 80px;
			z-index: 9;
			width: 100%;
			left: 0;
			right: 0;
			max-width: 1230px;
			margin: 0 auto;

			@media (max-width: 767px) {

				bottom: 50px;

				.search-mobile {
					background: rgba(75, 158, 226, .9);
					padding: 0 15px 15px;

					.search {
						background-color: var(--whiteColor);

						.form-control {
							border: none;
						}

						.btn {
							background-color: var(--whiteColor);
							color: var(--darkBlueColor);
							border: none;
						}
					}
				}
			}
		}

		.home-rating {
			.rating-star {
				position: relative;

				.fas {
					color: #46C400;
					font-size: 80px;
				}

				span {
					position: absolute;
					left: 0;
					right: 0;
					text-align: center;
					font-size: 26px;
					color: #fff;
					line-height: 86px;
					font-weight: 600;
				}
			}

			.rating-text {
				h5 {
					text-shadow: 1px 1px 1px #000;
				}

				p {
					font-size: 12px;
					text-shadow: 1px 1px 1px #000;
				}
			}
		}
	}



	.searchbar {
		background: rgba(75, 158, 226, .9);
		padding: 15px;

		@media (max-width: 767px) {
			padding-bottom: 8px;
		}

		.searchbox {

			.fas,
			.far {
				position: absolute;
				right: 20px;
				bottom: 10px;
				color: #111;
				color: var(--defaultColor);
				font-size: 16px;
				display: block;
				pointer-events: none;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}

	}

}